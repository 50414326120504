body {
	background: #ebf3f9;
	font-size: 16px;
	color: #000000;
	font-family: Lato, sans-serif
}
a {
	text-decoration: underline;
	color: #b17d2e;
	transition: .5s;
	outline: 0
}
a:hover {
	text-decoration: none;
	color: #b17d2e;
}
ul {
	margin: 0;
	padding: 0
}
ul li {
	list-style: none
}
.landingNav .navbar-nav{
	align-items: center;
}
.linkTabs .nav-item {
	margin: 0 5px;
}
.linkTabs .nav-item .nav-link{
	text-decoration: none;
	font-size: 16px;
	color: #fff !important;
	padding: 19px;
}

.linkTabs .nav-item .nav-link:hover{
	color: #f3e133 !important;
}
.up .linkTabs .nav-item .nav-link:hover{
	background: transparent;
}
.linkTabs .nav-item .nav-link.dropdown-toggle::after{
	display: none;
}
.linkTabs .dropdown-menu{
	background: #eaf3fa;
	border: 0;
	border-radius: 0;
	margin: 0;
}
.linkTabs .nav-item:hover a{
	background: transparent;
}
.linkTabs .nav-item .dropdown-item.active {
	background: #d4e1e7;	
	color: #000;
}
.linkTabs .dropdown-menu a{
	text-decoration: none;
	text-align: center;
}
.linkTabs .dropdown-menu a:hover{
	background: #d4e1e7;
}
.footerLogo img {
    width: 250px;
}
.btn{
	text-decoration: none !important;
}
.navbar-toggler{
	outline: none !important;
}
.max-widthCalander{
	max-width: 185px;
	border: 1px solid #d7d7d7;
	border-radius: .25rem;
	align-items: center;
	padding-right: 0px;
	margin-right: 14px;
}
.max-widthCalander .form-control{
	border: 0 !important;
	box-shadow: none !important;
}
.max-widthCalander .input-group-append {
    margin-left: -1px;
    width: 30px;
    text-align: center;
    display: block;
    color: #b17d2e;
}
.activityFilterHead {
    background: #fff;
    padding: 10px 15px;
    display: flex;
   	align-items: center;
   	border-top:1px solid #e6e6e6;
}
.activityTable td{
	font-size: 13px;

}
sup.dash_msg_counter {
    background: #ed2727;
    text-decoration: none;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    right: -8px;
    top: -8px;
}
.dash_msg_counter_panel {
	position: relative;
	display: inline-block;
}
.activityTable td:first-child{
	white-space: nowrap;
}
.activityTable td:last-child a{
	color: #9b9b9b;
	transition: .5s;
}
.activityTable td:last-child a:hover{
	color: #b17d2e;
}
.formFooter ul {
	display: inline-block;
	margin-top: 15px;
	text-align: center;
	width: 100%
}
.formFooter ul li {
	display: inherit;
	margin: 0 10px
}
.checkbox label, 
.radio label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkbox label:after, 
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #dcdcdc;
    background: #ededed;
    border-radius: 0em;
    width: 18px;
    height: 18px;
    float: left;
    margin-right: .5em;
    margin-top: 1px;
}

.radio .cr {
	border-radius: 50%;
	border: 1px solid #b17d2e;
	background: #f1f1f1;
}
.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: 10px;
    line-height: 0;
    top: 50%;
    left: 20%;
    color: #b17d2e;
}

.radio .cr .cr-icon {
    /*margin-left: 0.04em;*/
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

input[type=email],
input[type=number],
input[type=password],
input[type=text] {
	background-color: #f3f3f3;
	border: 1px solid #eaeaea;
	color: #000;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 0;
	width: 100%;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	-webkit-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	resize: none;
	box-shadow: none!important
}
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=text]:focus {
	background-color: #f3f3f3;
	border: 1px solid #b17d2e;
}
input:placeholder {
	color: #ccc
}
input[type=button],
input[type=file],
input[type=reset],
input[type=submit] {
	background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	border: 1px solid #b17d2e;
	color: #fff;
	padding: 12px 35px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	-webkit-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	margin: 5px auto 10px auto;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	width: auto;
	cursor: pointer;
	font-weight: 600;
	display: block;
	box-shadow: none !important;
}
input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover {
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	border: 1px solid #b17d2e;
	color: #ffffff;
}
input[type=button]:active,
input[type=reset]:active,
input[type=submit]:active {
	-moz-transform: scale(.95);
	-webkit-transform: scale(.95);
	-o-transform: scale(.95);
	-ms-transform: scale(.95);
	transform: scale(.95)
}
.border-top-zero{
	border-top: 0 !important;
}
.border-bottom-zero{
	border-bottom: 0 !important;
}
.btnDefaultRounded{
	background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	border: 1px solid #b17d2e;
	color: #fff;
	padding: 8px 45px 9px;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	border-radius: 50px;
	margin: 0 auto;
	transition: .5s;
	width: auto;
	cursor: pointer;
	display: block;
	box-shadow: none !important;
}
.btnDefaultRounded:hover{
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	border: 1px solid #b17d2e;
	color: #ffffff;
}
.btnType1 {
    background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
    border: 1px solid #b17d2e;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    box-shadow: none !important;
    outline: 0;
    transition: .5s;
    padding: 0 20px;
    font-weight: 400;
}
.btnType1:hover {
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
color: #fff !important;
border-color: #b17d2e;
}
.btnType2 {
	background: #b17d2e;
	border: 1px solid #b17d2e;
	color: #fff;
	text-transform: uppercase;
	font-size: 18px;
	box-shadow: none!important;
	outline: 0;
	transition: .5s;
	padding: 20px 25px
}
.btnType2:hover {
	background: 0 0;
	color: #b17d2e
}
.btnType3 {
	background: 0 0;
	border: 1px solid #b17d2e;
	color: #fff!important;
	text-transform: uppercase;
	font-size: 18px!important;
	box-shadow: none!important;
	outline: 0;
	transition: .5s;
	padding: 8px 20px!important
}
.btnType3:hover {
	background: #b17d2e;
	color: #fff!important
}
.buttonNote{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
}
.crediantialsBox {
	background: #fffdf1;
	padding: 120px 0 60px;
}
.crediantialsBox .container{
	background: url(../images/cediantialbg.png) no-repeat scroll 0 0;
	min-height: 650px;
}
.formBox {
	background: #fff;
	padding: 35px 0 20px;
	border-radius: 15px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.formBox .nav-tabs {
	border-bottom: 1px solid #d4d4d4
}
.formBox .tab-pane {
	padding: 0px 50px 20px;
}
.crediantialTitle {
	font-size: 24px;
	font-weight: 700;
	color: #000;
	text-align: center;
	margin-bottom: 35px;
}
.remForAction {
	display: flex;
	justify-content: space-between;
	align-items: center
}
.formBox .form-group.remForAction label {
	color: #969696
}
.formBox .nav-tabs .nav-item.nav-link {
	font-size: 20px;
	font-weight: 400;
	color: #969696;
	border-radius: 0;
	border: 0;
	border-bottom: 4px solid transparent;
	text-transform: uppercase
}
.formBox .nav-tabs .nav-item.nav-link:first-child {
	margin-left: 50px
}
.formBox .nav-tabs .nav-item.nav-link:last-child {
	margin-right: 50px
}
.formBox .nav-tabs .nav-item.nav-link:hover,
.formBox .nav-tabs .nav-link.active {
	background: 0 0;
	border-radius: 0;
	border: 0;
	border-bottom: 4px solid #b17d2e;
	color: #000
}
.formBox .form-group label {
	color: #000;
	font-size: 16px
}
.formFooter h6 {
	font-size: 16px;
	color: #000;
	margin-top: 25px
}
.formFooter h6 a {
	color: #b17d2e;
	text-decoration: underline
}
.formFooter h6 a:hover {
	color: #b17d2e;
	text-decoration: none
}
.userMainFooter{
	margin: 25px 0 30px;
}
.userMainFooter p{
	font-size: 10px;
	color: #c6e7ff;
}
.userMainFooter ul{
	display: inline-block;
	text-align: center;
	width: 100%;
	margin-bottom: 5px;
}
.userMainFooter ul li{
	display: inline;
	margin: 0 10px;
	color: #ffffff;
	font-size: 14px;
}
.userMainFooter ul li a{
	color: #c6e7ff;
	transition: .5s;
}
.userMainFooter ul li a:hover{
	text-decoration: underline;
}
.formFooter p{
	font-size: 12px;
	color: #000000;
	max-width: 75%;
	margin-left: auto;
	margin-right: auto;
}
.formFooter p span{
	color: #969696;
}
.credintialTitle{
	font-size: 20px;
	color: #000000;
	font-weight: 700;
	border-bottom: 1px solid #d4d4d4;
	padding-bottom: 20px;
	margin-bottom: 20px;
}
.pageStyle{
	background: #fff;
	border-radius: 7px;
}
.pageBox{
	padding: 15px;
}
.pageNavStyle{
	border: 0;
	display: flex;
	justify-content: space-between;
}
.pageNavStyle a{
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: 0 0 10px rgba(0,0,0,.13);
	border-radius: 5px;
	width: 23%;
	color: #000000;
	padding: 15px;
	transition: .5s;
}
.pageNavStyle a:hover,
.pageNavStyle a.active{
	background: #b17d2e !important;
	color: #ffffff !important;
}
.bg-navIcon1 {
    width: 32px; height: 26px;
    background: url('../images/bgNavIcon.png') -10px -186px;
}
.pageNavStyle a:hover .bg-navIcon1,
.pageNavStyle a.active .bg-navIcon1 {
    width: 32px; height: 26px;
    background-position: -10px -232px;
}
.bg-navIcon2 {
    width: 25px; height: 29px;
    background: url('../images/bgNavIcon.png') -10px -278px;
}
.pageNavStyle a:hover .bg-navIcon2,
.pageNavStyle a.active .bg-navIcon2 {
    width: 25px; height: 29px;
    background-position: -10px -327px;
}
.bg-navIcon3 {
    width: 25px; height: 25px;
    background: url('../images/bgNavIcon.png') -10px -96px;
}
.pageNavStyle a:hover .bg-navIcon3,
.pageNavStyle a.active .bg-navIcon3 {
    width: 25px; height: 25px;
    background-position: -10px -141px;
}
.bg-navIcon4 {
    width: 19px; height: 23px;
    background: url('../images/bgNavIcon.png') -10px -10px;
}
.pageNavStyle a:hover .bg-navIcon4,
.pageNavStyle a.active .bg-navIcon4 {
    width: 19px; height: 23px;
    background-position: -10px -53px;
}
#nav-tab-subMenu{
	padding: 15px 0;
	border-bottom: 1px solid #e6e6e6;
	border-top: 1px solid #e6e6e6;
	background: #f8f8f8;
	margin-top:15px;
}
#nav-tab-subMenu a{
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 1px solid #b17d2e;
	border-radius: 5px;
	color: #000000;
	padding: 15px;
	transition: .5s;
	margin-left: 10px;
	margin-right: 10px;
}
#nav-tab-subMenu a:first-child{
	margin-left: 15px;
}
#nav-tab-subMenu a:last-child{
	margin-right: 15px;
}
#nav-tab-subMenu a:hover,
#nav-tab-subMenu a.active{
	background: #b17d2e !important;
	color: #ffffff !important;
}

.bg-accountSubIcon1 {
    width: 32px; height: 32px;
    background: url('../images/accountSubIcon.png') -10px -210px;
}
#nav-tab-subMenu a:hover .bg-accountSubIcon1,
#nav-tab-subMenu a.active .bg-accountSubIcon1 {
    background-position: -10px -262px;
}
.bg-accountSubIcon2 {
    width: 32px; height: 32px;
    background: url('../images/accountSubIcon.png') -10px -314px;
}
#nav-tab-subMenu a:hover .bg-accountSubIcon2,
#nav-tab-subMenu a.active .bg-accountSubIcon2 {
    background-position: -10px -366px;
}
.bg-accountSubIcon3 {
    width: 31px; height: 31px;
    background: url('../images/accountSubIcon.png') -10px -108px;
}
#nav-tab-subMenu a:hover .bg-accountSubIcon3,
#nav-tab-subMenu a.active .bg-accountSubIcon3 {
    background-position: -10px -159px;
}
.bg-accountSubIcon4 {
    width: 32px; height: 29px;
    background: url('../images/accountSubIcon.png') -10px -10px;
}
#nav-tab-subMenu a:hover .bg-accountSubIcon4,
#nav-tab-subMenu a.active .bg-accountSubIcon4 {
    background-position: -10px -59px;
}
.subPageTitle {
    font-size: 20px;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-style: italic;
}
.profileCard p{
	font-size: 16px;
	color: #969696;
}
.profileCard p span{
	display: block;
	color: #b17d2e;
	text-decoration: underline;
	font-weight: 700;
}
.profileCard p small{
	color: #969696;
}
.profileCard p small a{
	color: #b17d2e;
	text-decoration: underline;
}
.profileCard p small a:hover{
	text-decoration: none;
}
.blackText{
	color: #000000 !important;
	text-decoration: none !important;
}
.yellowText{
	color: #ffc107 !important;
}

.accountForm input[type=button],
.accountForm input[type=file],
.accountForm input[type=reset],
.accountForm input[type=submit]{
	padding: 9px 40px;
}

.accountForm input[type=email],
.accountForm input[type=number],
.accountForm input[type=password],
.accountForm input[type=text]{
	background: #ffffff;
}
.roundedCorner{
	border-radius: 7px !important;
}
.accountForm{
	border-top: 1px solid #e6e6e6;
}
.table.bankList td, .table.bankList th{
	border-top: 0;
	white-space: nowrap;
}
.table.bankList thead th{
	border-bottom: 1px solid #e6e6e6;
}
.bankList td a{
	color: #b17d2e;
	transition: .5s;
	text-decoration: underline;
}
.bankList td a:hover{
	color: #0091ff;
	text-decoration: none;
}
.buttonNote p{
	color: #969696;
	font-size: 14px;
}
.buttonNote p span{
	color: #000000;
}
.modalHead {
    padding: 15px;
    text-align: center;
}
button.close {
	position: absolute;
	top: 15px;
	right: 15px;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: #000;
    opacity: 1;
    outline: none;
}
button.close:hover{
	color: #0091ff;
}
.modalHead h4{
	font-size: 16px;
	font-weight: 700;
}
.modalHead p {
	font-size: 14px;
}
.css-yk16xz-control{
	border-color: #d7d7d7;
}
.input-group-append .css-yk16xz-control{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px; 
}
.input-group-prepend .css-yk16xz-control{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px; 
}
.input-group-append .css-2b097c-container{
	min-width: 110px;
	box-shadow: none !important;
}
.input-group-prepend .css-2b097c-container{
	min-width: 90px;
	box-shadow: none !important;
}
.css-1okebmr-indicatorSeparator{
	display: none;
}
.historyAccordian #accordion .card-header .btn {
    padding: 0 0 15px 0;
    display: block;
    width: 100%;
    text-align: left;
    color: #4c4c4c;
    font-size: 14px;
    text-decoration: none;
    white-space: normal;
    box-shadow: none !important;
}
.historyAccordian #accordion .card-header .btn.btn-link.collapsed{
  color: #000000;
  text-decoration: none;
  transition: .5s;
  padding-bottom: 15px;
  transition: .5s;
  white-space: normal;
}
.historyAccordian #accordion .card-header .btn.btn-link.collapsed:hover{
  text-decoration: none;
  color: #1e2026;
}
.historyAccordian #accordion .card-header:hover .btn.btn-link.collapsed{
  text-decoration: none;
  color: #b17d2e;
}
.historyAccordian #accordion .card-header .btn i {
    float: right;
    margin-top: 0px;
    padding: 8px;
    color: #2c6ed5;
    font-size: .85rem;
    transition: .5s;
}

.historyAccordian #accordion .card-header {
    padding: 0px;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 0;
}
.historyAccordian #accordion .card{
  border-radius: 0px;
  border:0;
  background: transparent;
}
.historyAccordian #accordion .card-body {
    padding: 15px;
    background: #f8f8f8;
}

.historyAccordian #accordion .card-header [data-toggle="collapse"] .fa:before {  
  content: "\f068";
  color: #b17d2e;
}

.historyAccordian #accordion .card-header [data-toggle="collapse"].collapsed .fa:before {
  content: "\f067";
  color: #b17d2e;
}
.historyAccordian #accordion .card-header:hover .btn i:before{
	color: #b17d2e !important;
}
.historyAccordian #accordion .card-header .btn .numList {
  background: #ff8300;
}
.historyAccordian #accordion .card-header .btn.btn-link.collapsed .numList{
  background: #2c6ed5;
}
.depositCard {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;
}
.depositCard:last-child{
	margin-bottom: 0;
	border-bottom: 0;
}
.depositCard p{
	color: #646464;
}
.depositCard p small{
	display: block;
	font-size: 12px;
}

.dcRight{
	text-align: right;
}
.dcRight h4{
	font-size: 20px;
	color: #000000;
}
.dcRight h4 small{
	display: block;
	font-size: 16px;
	color: #646464;
}
.orangeText{
	color: #ff6c00 !important;
}
.greenText{
	color: #00a44d !important;
}
.dcRight p small{
	color: #646464 !important;
}
.form-control:disabled,
.form-control[readonly]{
	background: #f3f3f3 !important;
}
.form-group label a i.fa-info-circle{
	color: #adadce;
}
.input-group-text{
	background: #ffffff;
}
.input-group .form-control{
	border-right:0 !important;
}
.input-group .form-control:focus{
	border-color: #eaeaea !important;
}
.buySellNav{
	padding: 25px 15px;
	display: flex;
	justify-content: space-between;
	border-bottom: 0;
	background: #f8f8f8;
	margin-bottom: 30px;
	margin-top:25px;
}
.buySellNav a{
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 1px solid #b17d2e !important;
	border-radius: 5px;
	width: 23%;
	color: #000000;
	padding: 15px;
	transition: .5s;
}
.buySellNav a:hover,
.buySellNav a.active{
	background: #b17d2e !important;
	color: #ffffff !important;
}

.bg-buySellSubIcon1 {
    width: 32px; height: 32px;
    background: url('../images/buySellSubIcon.png') -10px -192px;
}
.buySellNav a:hover .bg-buySellSubIcon1,
.buySellNav a.active .bg-buySellSubIcon1 {
    background-position: -10px -244px;
}
.bg-buySellSubIcon2 {
    width: 32px; height: 32px;
    background: url('../images/buySellSubIcon.png') -10px -296px;
}
.buySellNav a:hover .bg-buySellSubIcon2,
.buySellNav a.active .bg-buySellSubIcon2 {
    background-position: -10px -348px;
}
.bg-buySellSubIcon3 {
    width: 27px; height: 27px;
    background: url('../images/buySellSubIcon.png') -10px -98px;
}
.buySellNav a:hover .bg-buySellSubIcon3,
.buySellNav a.active .bg-buySellSubIcon3 {
    background-position: -10px -145px;
}
.bg-buySellSubIcon4 {
    width: 26px; height: 24px;
    background: url('../images/buySellSubIcon.png') -10px -54px;
}
.buySellNav a:hover .bg-buySellSubIcon4,
.buySellNav a.active .bg-buySellSubIcon4 {
    background-position: -10px -10px;
}
.getStartedDash{
	border-top:1px solid #e6e6e6;
	margin-top: 15px;
	padding-top: 20px;
	padding-left: 15px;
	padding-right: 15px;
}
.gsdHead{
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
	margin-top: 3px;
}
.gsdHead h3{
	font-size: 20px;
	color: #000000;
	font-weight: 700;
	font-style: italic;
}
.gsdHead h6{
	font-size: 12px;
	color: #000000;
	font-style: italic;
	font-weight: 600;
}
.gsdHead h6 span{
	color: #969696;
	font-style: normal;
}
.gsdcontent a {
    display: block;
    border: 1px solid #dcdcdc;
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 7px;
    color: #828282;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .5s;
}
.gsdcontent a:hover{
	border: 1px solid #b17d2e;
	color: #b17d2e;
}
.dashRadio {
    display: flex;
    align-items: center;
}
.dashRadio label.labelTitle {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    margin-right: 35px;
}
#nav-tab-dashPairMain {
    padding-left: 15px;
    border-color: #0091ff;
}
#nav-tab-dashPairMain .nav-item{
	 margin-bottom: 0;
}
#nav-tab-dashPairMain .nav-link{
	color: #646464;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	padding: 5px 20px;
	margin-right: 5px;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #0091ff #0091ff transparent;
}
#nav-tab-dashPairMain .nav-item.show .nav-link,
#nav-tab-dashPairMain .nav-link.active {
    color: #ffffff;
    background-color: #b17d2e;
    border-color: #0091ff #0091ff #b17d2e;
}
.dashBuyCard{
	background: #e8f6e6;
	border: 1px solid #22a208;
	text-align: center;
	padding-bottom: 5px;
	padding-top: 10px;
	border-radius: 7px;
}
.dashBuyCard h2{
	font-size: 22px;
	color: #22a208;
	line-height: 32px;
}
.dashBuyCard h2 span{
	display: block;
	color: #646464;
	font-size: 16px;
}
.dashSellCard{
	background: #fce6f1;
	border: 1px solid #dd0a77;
	text-align: center;
	padding-bottom: 5px;
	padding-top: 10px;
	border-radius: 7px;
}
.dashSellCard h2{
	font-size: 22px;
	color: #dd0a77;
	line-height: 32px;
}
.dashSellCard h2 span{
	display: block;
	color: #646464;
	font-size: 16px;
}
.noteText{
	font-size: 14px;
	color: #969696;
	text-align: center;
}
.noteText span{
	color: #000000;
	font-weight: 700;
	font-style: italic;
}
.dashBuySellPair{
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 20px;
}
.dashBuySellPair h1{
	font-size: 20px;
	color: #b17d2e;
	font-weight: 700;
	margin-bottom: 20px;
}
.dashBuySellPair h1 small{
	color: #000000;
	font-size: 20px;
}
.dashBuySellPair h2{
	font-size: 20px;
	color: #000000;
	font-weight: 700;
}
.dashBuySellPair h3{
	font-size: 16px;
	color: #000000;
	font-weight: 700;
}
.dashBalanceMain{
	background: #f8f8f8;
	padding: 15px;
	margin-top: 15px;
}
.dbmHead{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.dbmHead h4{
	font-size: 16px;
	color: #000000;
	font-weight: 700;
	margin: 0;
}
.historyLogin{
	background: #f8f8f8;
	margin-top: 5px;
	padding: 15px;
}
.react-switch-bg{
	height: 16px !important;
	background: #dedede !important;
}
.react-switch-handle{
	width: 25px !important;
	height: 25px !important;
	box-shadow: none !important;
	background: #b17d2e !important;
}
 
.twoFactorSec {
	border-top: 1px solid #e6e6e6;
}
.tfsHead{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tfsHead h3{
	font-size: 18px;
	font-weight: 700;
}
.twoFactorSec h6{
	color: #828282;
	font-size: 16px;
	margin-top: 20px;
	margin-bottom: 0px;
	text-align: center;
}
.twoFACode{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
}
.twoFactorSec .form-group label{
	color: #828282;
}
.twoFactorSec .form-group p{
	color: #828282;
	margin-top:10px;
}
.twoFactorSec .form-group p span{
	color: #000000;
}
.inputSwitchGroup {
    display: flex;
    align-items: center;
}
.inputSwitchGroup label{
	margin-bottom: 0;
	margin-right: 10px;
}
.twoFactorSec input[type="button"],
.twoFactorSec input[type="file"],
.twoFactorSec input[type="reset"],
.twoFactorSec input[type="submit"]{
	padding: 8px 45px 9px !important;
	font-size: 16px !important;
	text-transform: capitalize !important;
	font-weight: 400 !important;
}
.twoFactorSec .form-control:disabled,
.twoFactorSec .form-control[readonly]{
	background: #f3f3f3;
}
.securityFormTitle{
	font-size: 18px;
	font-weight: 700;
	margin-top: 20px;
	margin-bottom: 20px;
}
.stHead {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    margin-top: 15px;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}
.stHead h3 {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    font-style: italic;
}

.stForm h3{
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 15px;
}
.tdMain{
	padding: 30px 15px 15px;
	border-top:1px solid #e6e6e6;
}
.tdMain h3{
	font-size: 18px;
	font-weight: 700;
}
.supporTicketAccordian{
  padding: 15px 0px 0px;
}
.supporTicketAccordian #accordion .card-header .btn {
    padding: 0;
    display: block;
    width: 100%;
    text-align: left;
    color: #ffffff;
    font-weight: 500;
    font-size: 1.2rem;
    text-decoration: none;
    border-bottom: 0;
    white-space: normal;
    display: flex;
    justify-content: space-between;
    box-shadow: none !important;
}
.supporTicketAccordian #accordion .card-header .btn.btn-link.collapsed{
  color: #c8c8c8;
  text-decoration: none;
  transition: .5s;
  border-bottom: 0px solid #b17d2e;
  border-radius: 0;
  padding-bottom: 0px;
  transition: .5s;
  font-weight: 500;
  white-space: normal;
  display: flex;
  justify-content: space-between;
}
.supporTicketAccordian #accordion .card-header .btn.btn-link.collapsed:hover{
  text-decoration: none;
  color: #b17d2e;
}
.supporTicketAccordian #accordion .card-header:hover .btn.btn-link.collapsed{
  text-decoration: none;
  color: #b17d2e;
}
.supporTicketAccordian #accordion .card-header .btn i {
    float: right;
    margin-right: 0px;
    margin-top: 0px;
    padding: 10px 0px 0px;
    color: #b17d2e;
    font-size: .85rem;
    transition: .5s;
}

.supporTicketAccordian #accordion .card-header {
    padding: .55rem 0rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 0;
}
.supporTicketAccordian #accordion .card{
  border-radius: 7px;
  border:1px solid #dcdcdc;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.supporTicketAccordian #accordion .card-body {
    padding: 15px;
    background: #f8f8f8;
}
.supporTicketAccordian #accordion .card-body p{
  color: #646464;
  font-size: 13px;
}
.supporTicketAccordian #accordion .card-body p.metaChatDetails {
    color: #898989;
}
.supporTicketAccordian #accordion .card-header [data-toggle="collapse"] .fa:before {  
  content: "\f068";
  color: #b17d2e;
}

.supporTicketAccordian #accordion .card-header [data-toggle="collapse"].collapsed .fa:before {
  content: "\f067";
  color: #c8c8c8;
}
.supporTicketAccordian #accordion .card-header:hover .btn i:before{
  color: #b17d2e !important;
}
.supporTicketAccordian #accordion .card-header .btn .numList {
  background: #ff8300;
}
.supporTicketAccordian #accordion .card-header .btn.btn-link.collapsed .numList{
  background: #b17d2e;
}
.stHeadText {
    font-size: 16px;
    color: #000000;
}
.stHeadText small {
	display: block;
	color: #646464;
	font-size: 12px;
}
.css-yk16xz-control{
	background-color: #f3f3f3 !important;
	border: 1px solid #eaeaea !important;
	box-shadow: none !important;
}
textarea.form-control{
	resize: none;
	box-shadow: none !important;
	background-color: #f3f3f3;
	border: 1px solid #eaeaea;
}
textarea.form-control:focus{
	background-color: #f3f3f3;
	border: 1px solid #b17d2e;
}
.border-bottom-0{
	border-bottom: 0 !important;
}
.btnTypeTrade2{
    border-radius: 7px !important;
    padding: 6px 25px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-transform: capitalize !important;
}
.submitChat{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.supportSubject h4 span{
	color: #646464;
}
.supportSubject h4 {
    font-size: 16px;
    color: #000000;
    border-bottom: 1px solid #ebebeb;
    padding: 0 0 15px;
    margin-bottom: 15px;
}
.greyText{
	color: #828282;
}
.settingsList{
	margin-top: 15px;
}
.settingsList li{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}
.settingsList li:last-child{
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.settingsList li .css-2b097c-container{
	min-width: 200px;
}
.caSettings{
	border-top:1px solid #e6e6e6;
	margin-top: 30px;
	padding-top: 20px;
}
.caSettings h3{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}
.caSettings p{
	color: #828282;
}

.verifyNav{
	padding: 25px 15px;
	display: flex;
	justify-content: space-between;
	border-top:1px solid #e6e6e6;
	border-bottom: 0;
	background: #f8f8f8;
	margin-bottom: 0px;
	margin-top:10px;
}
.verifyNav a{
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 1px solid #b17d2e !important;
	border-radius: 5px;
	width: 48%;
	color: #000000;
	padding: 15px;
	transition: .5s;
}
.verifyNav a:hover,
.verifyNav a.active{
	background: #b17d2e !important;
	color: #ffffff !important;
}
.bg-verify1Icon {
    width: 40px; height: 45px;
    background: url('../images/verifyNavIcons.png') -10px -10px;
}
.verifyNav a:hover .bg-verify1Icon,
.verifyNav a.active .bg-verify1Icon {
    width: 40px; height: 45px;
    background: url('../images/verifyNavIcons.png') -10px -75px;
}
.bg-verify2Icon {
    width: 40px; height: 45px;
    background: url('../images/verifyNavIcons.png') -10px -140px;
}
.verifyNav a:hover .bg-verify2Icon,
.verifyNav a.active .bg-verify2Icon{
    width: 40px; height: 45px;
    background: url('../images/verifyNavIcons.png') -10px -205px;
}
.verifyContent{
	padding: 15px;
	background: #f8f8f8
}
.verifyContent dt{
	margin-bottom: 10px;
	font-size: 14px;
}
.verifyContent dd{
	font-size: 14px;
}
.verifyContent dd span{
	color: #b17d2e;
}
.verifyContent.whiteBg{
	background: #ffffff;
	display: inline-block;
	width: 100%;
	text-align: center;
	padding-top: 30px;
}
.verifyContent.whiteBg h3{
	font-size: 20px;
	color: #000000;
	font-weight: 700;
	font-style: italic;
}
.verifyContent.whiteBg h6{
	font-size: 14px;
	font-weight: 700;
}
.verifyContent.whiteBg p{
	font-size: 14px;
	color: #969696;
}
.buyVerifySection {
    background: #f8f8f8;
    padding: 15px;
    margin-top: -30px;
    margin-bottom: 25px; 
}
.group-label{
	display: flex;
	justify-content: space-between;
}
.group-label span{
	font-size: 16px;
	color: #000000;
	font-weight: 700;
}
.group-label span.textRed small{
	color: #df1818;
	font-size: 14px;
}
.group-label span.textRed a{
	color: #df1818;
	font-size: 14px;
	text-decoration: underline;
	transition: .5s;
}
.group-label span.textRed a:hover{
	text-decoration: none;
}
.group-label span small{
	font-size: 14px;
	color: #828282;
}
.senderAddress span{
	color: #b17d2e;
	text-decoration: underline;
}
.qrCodeBg{
	background: url(../images/receiveqrCodeBg.png) no-repeat scroll 0 0;
	width: 248px;
	height: 246px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 25px auto;
}


.bg-p2pSubIcon1 {
   width: 28px; height: 28px;
    background: url('../images/p2pNavIcons.png') -10px -200px;
}
#nav-tab-subMenu a:hover .bg-p2pSubIcon1,
#nav-tab-subMenu a.active .bg-p2pSubIcon1 {
    background-position: -10px -248px;
}
.bg-p2pSubIcon2 {
    width: 28px; height: 27px;
    background: url('../images/p2pNavIcons.png') -10px -10px;
}
#nav-tab-subMenu a:hover .bg-p2pSubIcon2,
#nav-tab-subMenu a.active .bg-p2pSubIcon2 {
    background-position: -10px -57px;
}
.bg-p2pSubIcon3 {
    width: 27px; height: 29px;
    background: url('../images/p2pNavIcons.png') -10px -345px;
}
#nav-tab-subMenu a:hover .bg-p2pSubIcon3,
#nav-tab-subMenu a.active .bg-p2pSubIcon3 {
    background-position: -10px -296px;
}
.bg-p2pSubIcon4 {
    width: 28px; height: 28px;
    background: url('../images/p2pNavIcons.png') -10px -200px;
}
#nav-tab-subMenu a:hover .bg-p2pSubIcon4,
#nav-tab-subMenu a.active .bg-p2pSubIcon4 {
    background-position: -10px -152px;
}
.p2pMain{
	background: #f8f8f8;
	border-top: 1px solid #e6e6e6;
}
.bg-transparent{
	background: transparent !important;
}
.p2pRadio {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    margin-bottom: .75rem;
}
.p2pRadio label.labelTitle {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    margin-right: 35px;
    color: #000000;
}
.p2pMain .form-control{
	background: #ffffff !important;
}
h2.balanceTitle {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    text-align: center;
    margin: 25px 0 20px;
}
.p2pFindResult{
	background: #ffffff;
	border-top: 1px solid #e6e6e6;
}
.pfrCard {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #e6e6e6;
}
.pfrLeft h3{
	color: #000000;
	font-size: 20px;
	margin-bottom: 0;
}
.pfrLeft h3 small{
	font-size: 16px;
}
.pfrLeft p{
	color: #969696;
	font-size: 16px;
	margin-bottom: 0;
}
.pfrLeft ul{
	display: flex;
	align-items: center;
}
.pfrLeft ul li{
	margin-right: 25px;
}
.pfrLeft ul li a{
	font-size: 14px;
	color: #969696;
	transition: .5s;
}
.pfrLeft ul li a:hover{
	color: #b17d2e;
}
.pfrLeft ul li i{
	font-size: 13px;
}
.pfrLeft ul li.userLink a{
	color: #b17d2e;
	text-decoration: underline;
	transition: .5s;
}
.pfrLeft ul li.userLink a:hover{
	text-decoration: none;
}
.pfrLeft ul li i.fa-circle{
	font-size: 10px;
}
.pfrLeft ul li .active i.fa-circle{
	color: #07c9b0;
}
.pfrLeft ul li .active i.fa-star{
	color: #ffc107;
}
.pfrLeft ul li .active i.fa-thumbs-up{
	color: #b17d2e;
}
.p2pBuyBtn{
	background: #bdffab;
	border: 2px solid #2ed801;
	border-radius: 50px;
	padding: 8px 40px 9px;
	color: #25ac01;
	font-size: 17px;
	transition: .5s;
}
.p2pBuyBtn:hover{
	color: #ffffff;
	background: #2ed801;
}
.myAdsList .row{
	border-top: 1px solid #ebebeb;
	padding-top: 15px;
}
.myAdListCard p{
	color: #646464;
	font-size: 14px;
}
.myAdListCard p small{
	display: block;
}
.btnType4{
	background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
border: 1px solid #b17d2e;
	color: #ffffff;
	box-shadow: none !important;
	margin: 0 !important;
	font-size: 14px;
	transition: .5s;
	min-width: 120px;
}
.btnType4:hover{
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	color: #fff !important;
	border-color: #b17d2e;
}
.btnType5{
	background: transparent;
	color: #fff;
	box-shadow: none !important;
	border: 1px solid #efdb33;
	margin: 0 !important;
	font-size: 14px;
	transition: .5s;
	min-width: 120px;
}
.btnType5:hover{
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	color: #fff !important;
	border-color: #b17d2e;
}
.postAdsIdentity {
    margin-bottom: 0;
    margin-top: -10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
}
.postAdsIdentity h5 {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
}
.postAdsIdentity h5 small{
    text-align: center;
    display: block;
    font-size: 14px;
    font-style: normal;
}
.postAdTitle{
	font-size: 16px;
	font-weight: 600;
	font-style: italic;
	margin-bottom: 15px;
}
.postAddForm{
	background: #ffffff;
}
.postADAgreement {
    justify-content: flex-start !important;
    align-items: flex-start !important;
}
.postADAgreement .agreeContent{
	max-width: 95%;
	font-size: 14px;
	color: #969696;
}
.postADAgreement .agreeContent a{

	color: #b17d2e;
}
.postADAgreement .cr{
	margin-top: 4px;
}
.balanceTitle small{
	color: #969696;
}
.backPageTitle{
	text-align: center;
	font-size: 20px;
	color: #000000;
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 12px;
	margin-bottom: 12px;
	position: relative;
}
.backPageTitle a{
	position: absolute;
	top: 5px;
	color: #000;
	left: 0px;
}
.backPageTitle small{
	font-size: 16px;
}
.p2pBuyBox{
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 15px;
}
.p2pBuyBox h2{
	font-size: 20px;
	color: #000000;
	margin-bottom: 24px;
}
.p2pBuyBox h2 small{
	display: block;
	font-size: 16px;
	color: #969696;
}
.modalHead h2 {
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 15px;
}
.confirmBuyDetails h3{
	text-align: center;
	font-size: 20px;
}
.confirmBuyDetails h3 small{
	display: block;
	color: #969696;
}
.flexJustifyCenter{
	align-items: center !important;
	justify-content: center !important;
}
.successBox {
	border-bottom: 1px solid #e6e6e6;
	padding: 15px;
}
.successBox h3{
	font-size: 18px;
	font-weight: 700;
	font-style: italic;
	text-align: center;
}
.successBox h3.greenNewText{
	color: #35c802;
}
.successBox h4{
	font-size: 20px;
	font-weight: 600;
	text-align: center;
}
.successBox h4 small{
	color: #929292;
	font-size: 16px;
}
.successBox p{
	font-size: 16px;
	font-weight: 400;
	text-align: center;
}
.textGrey label{
	color: #969696 !important;
}
.profileAccordian .card{
	border-top: 0 !important;
	border-bottom: 1px solid #dfdfdf !important;
}
.profileAccordian .card:first-child{
	border-top: 1px solid #dfdfdf !important;
}
.profileAccordian .card-header{
	border-bottom: 0 !important;
	background: #ffffff !important;
}
.profileAccordian .card-body{
	background: #f8f8f8;
}
.profileAccordian #accordion .card-header [data-toggle="collapse"] .fa::before {
    content: "\f068";
    color: #b17d2e;
}
.profileAccordian #accordion .card-header [data-toggle="collapse"].collapsed .fa::before {
	content: "\f067";
	color: #b17d2e;
}
.profileUserLabel{
	font-size: 16px;
	color: #969696;
}
.profileUserDetail{
	font-size: 16px;
	color: #969696;
	position: relative;
	display: block;
}
.profileUserDetail i{
	position: absolute;
	right: 0;
	top: 0;
	color: #b17d2e;
}
.handSymbol{
	cursor: pointer;
}
.blueText{
	color: #7a5f39 !important;
}
.pinkText{
	color: #d20a97 !important;
}
.textGrey{
	color: #949494 !important;
}
.commentList li{
	border-bottom: 1px solid #dfdfdf;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.commentList li p{
	margin-bottom: 0;
}
.flexColumn{
	display: flex;
}
.landingBanner {
    background: #616161 url(../images/heademaprbg.png) no-repeat scroll center center;
    padding: 100px 0 30px;
}
.landingBannerContent{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}
.landingBannerContent h2{
	color: #f3e133;
	font-size: 46px;
	font-weight: 700;
	margin-bottom: 20px;
	margin-top: 30px;
}
.landingBannerContent h6{
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	font-style: italic;
	text-align: center;
	display: block;
	width: 100%;
}
.landingBannerImg{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}
.landingNav{
	background: #616161 !important;
	/* padding: 0px !important; */
	transition: .5s;
}
.login_nav .landingNav {
 background: #616161 !important;
}
.landingNav.Scroll.up{
	background: #616161 !important;
}
.btnBorder{
	border: 1px solid #b17d2e;
	color: #12326b !important;
	box-shadow: none !important;
	outline: none !important;
	background: #ffffff;
	min-width: 105px;
	padding-left: 15px;
	padding-right: 15px;
} 
.btnBorder:hover{
	background: #b17d2e !important;
	color: #ffffff !important;
}
.btnBlue{
	border: 1px solid #b17d2e;
	color: #ffffff !important;
	box-shadow: none !important;
	outline: none !important;
	/* background: #b17d2e; */
	background: rgb(177,125,46);
	background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	min-width: 105px;
	padding-left: 15px !important;
	padding-right: 15px !important;
} 
.btnBlue:hover{
	background: rgb(177,125,46);
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	color: #fff !important;
	border-color: #b17d2e;
}
.btnGrey{
	border: 1px solid #909090;
	color: #8f8f8f !important;
	box-shadow: none !important;
	outline: none !important;
	background: #f3f3f3;
	min-width: 105px;
	padding-left: 15px;
	padding-right: 15px;
	transition: 0.2s all;
} 
.btnGrey:hover{
	background: #039be5 !important;
	color: #ffffff !important;
	border-color: #039be5;
}
.pointer {
	cursor: pointer;
  }
.btnBlueBordered{
	border: 1px solid #b17d2e;
	color: #fff !important;
	box-shadow: none !important;
	outline: none !important;
	background: rgb(177,125,46);
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	min-width: 105px;
	padding-left: 15px;
	padding-right: 15px;
} 
.btnBlueBordered:hover{
	background: rgb(177,125,46);
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	color: #fff !important;
	border-color: #b17d2e;
}
.landingNav .btnBlue:hover{
	background: rgb(177,125,46);
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	color: #fff !important;
	border-color: #b17d2e;
}
.processSteps {
    background: #ffffff;
    padding: 50px 0 60px;
}
.processSteps h2{
	color: #000000;
	font-size: 30px;
	font-weight: 700;
	margin-bottom: 20px;
}
.processSteps h3{
	color: #787878;
	font-size: 30px;
	font-weight: 400;
}
.processSteps h2 span{
	font-weight: 700;
	font-style: italic;
}
.verifyContentLeft{
	background: url(../images/coinFriendsIconBig.png) no-repeat scroll 0 0;
	width: 100%;
	padding: 60px 0 0 60px;
	position: sticky;
	top: 0;
}
.verifyContentLeft ul{
	padding: 25% 0 0 20%;
}
.verifyContentLeft ul li{
	margin-bottom: 10px;
}
.verifyContentLeft ul li a{
	font-size: 16px;
	color: #727272;
	padding: 7px 0;
}
.landingVerifyCard {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 25px;
    margin-bottom: 25px;
}
.landingVerifyCard:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0;
}
.verifyContentLanding img{
	margin: 0 0 30px;
	filter: grayscale(1);
	transition: .5s;
	opacity: .35;
}
.verifyContentLanding:hover img{
	filter: unset;
	opacity: 1;
}

.verifyContentLanding p{
	margin-bottom: 0;
}

.verifyNumberCard{
	min-width: 95px;
}
.verifyNumberCard span{
	display: inline-flex;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	color: #000000;
	font-size: 24px;
	justify-content: center;
	align-items: center;
}

.verifyNumberCard span.blueBg{
	background: #f3e133 !important;
}
.verifyNumberCard span.greyBg{
	background: #f7f6ff !important;
}
.verifyNumberCard span.orangeBg{
	background: #fcf2eb !important;
}
.verifyNumberCard span.greenBg{
	background: #f5fff3 !important;
}

.landingVerifyCard h3{
	font-size: 24px;
	color: #b17d2e;
	margin-bottom: 20px;
	font-style: italic;
	font-weight: 700;
}
.landingVerifyCard h3 span{
	font-weight: 700;
	font-style: italic;
}

.landingVerifyCard p{
	color: #8d8d8d;
}
.landingVerifyCard h4{
	color: #b17d2e;
	font-size: 20px;
	font-weight: 700;
	margin: 15px 0;
}
.paymentMethods{
	background: #ffffff;
	padding: 50px 0 50px;
}
.paymentMethods h2{
	color: #000000;
	font-size: 30px;
	text-align: center;
	margin-bottom: 45px;
	font-weight: 700;
}
.paymentMethods h2 span{
	color: #787878;
	font-weight: 400;
}
.landPMCard{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.landPMCard img{
	margin-bottom: 15px;
}
.landPMCard h3{
	font-size: 20px;
	color: #050505;
	text-align: center;
	font-weight: 700;
	font-style: italic;
}
.ourPartners{
	background: #ebf3f9;
	padding: 60px 0;
}
.ourPartners h2{
	color: #12326b;
	font-size: 32px;
	text-align: center;
	margin-bottom: 40px;
}
.ourPartners h2 span{
	font-weight: 700;
	font-style: italic;
}
.partnersLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.partnersLogo a {
    display: block;
    margin: 0 40px;
}
.mainFooter{
	background: #616161;
	padding-top: 60px;
}
.footerBottomBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #808080;
    padding: 15px 0 20px;
    margin-top: 35px;
}
.footerBottomBox p{
	margin-bottom: 0;
	color: #f0f0f0;
	font-size: 14px;
}
.footerBottomLinks{
	display: flex;
}
.footerBottomLinks li{
	font-size: 14px;
	margin-left: 15px;
}
.footerBottomLinks li a{
	color: #f0f0f0;
	transition: .5s;
	text-decoration: none;
}
.footerBottomLinks li a:hover{
	color: #f3e133;
}
.subscribForm span.input-group-text {
    border: transparent;
}
.middLinkFooter h3{
	font-size: 18px;
	font-weight: 400;
	color: #f3e133;
	margin-bottom: 15px;
}
.middLinkFooter ul li{
	margin-top: 10px;
}
.middLinkFooter ul li a{
	color: #f0f0f0;
	transition: .5s;
	font-size: 14px;
	text-decoration: none;
}
.middLinkFooter ul li a:hover{
	color: #f3e133;
	transition: .5s;
}
.footerSubscribe h3{
	font-size: 18px;
	font-weight: 500;
	color: #f3e133;
	text-align: right;
	margin-bottom:18px;
}
.footerSubscribe .input-group-append .btnBlue{
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.footerSubscribe .form-control{
	background: #f8f8f8;
	border-color: #f8f8f8;
}
.soacialFooterLinks {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}
.soacialFooterLinks li{
	margin-left: 18px;
}
.soacialFooterLinks li a{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	text-align: center;
	background: #b17d2e;
	border-radius: 50%;
	color: #ffffff;
	transition: .5s;
	text-decoration: none;
}
.soacialFooterLinks li a:hover{
	background: #b17d2e;
}
.satisfiedCustomer{
	background: #ffffff;
	padding: 60px 0 80px;
}
.satisfiedCustomer h2{
	color: #12326b;
	font-size: 32px;
	text-align: center;
	margin-bottom: 40px;
}
.satisfiedCustomer h2 span{
	font-weight: 700;
	font-style: italic;
}
.satisfiedCustomer .carousel-indicators{
	margin-bottom: 0 !important;
	bottom: -20px;
}
.satisfiedCustomer .carousel-indicators li{
	background: #d9d9d9;
	line-height: 0;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	padding: 0;
	font-size: 0;
	border: 0;
	margin-right: 12px;
	opacity: 1;
}
.satisfiedCustomer .carousel-indicators li:last-child{
	margin-right: 0;
}
.satisfiedCustomer .carousel-indicators li.active{
	background: #b17d2e;
}
.satisfiedCustomer .carousel-item img{
	width: 100%;
	max-width: 100px;
	max-height: 100px;
	margin: 0 auto;
	display: block;
	border-radius: 50%;
	margin-bottom: 15px;
	position: relative;
}
.satisfiedCustomer .carousel-item::before{
	content: "";
	background: url(../images/customerSayQuote.png) no-repeat scroll 0 0;
	width: 56px;
	height: 54px;
	position: absolute;
	top: 0px;
	left: 100px;
}
.satisfiedCustomer .carousel-item h3{
	font-size: 18px;
	color: #000000;
	text-align: center;
}
.satisfiedCustomer .carousel-item h4{
	font-size: 14px;
	color: #b17d2e;
	margin-bottom: 20px;
	text-align: center;
}
.satisfiedCustomer .carousel-item p{
	font-size: 14px;
	color: #767676;
	text-align: center;
	max-width: 70%;
	margin-right: auto;
	margin-left: auto;
}
.landingTable {
    background: #fff;
    box-shadow: 0 0 25px rgba(0,0,0,.15);
    border-radius: 15px;
	margin-bottom: 20px;
}

.landingTable.buyTable{
	background: #f5fdfa;
	position: relative;
	padding: 35px 0 5px;
}
.landingTable.sellTable{
	background: #fef7f9;
	position: relative;
	padding: 35px 0 5px;
}
.buyTable .table td,
.sellTable .table td{
	border-top: 0;
}
.buyTable .table td label,
.sellTable .table td label{
	margin-bottom: 0;
	font-size: 16px;
	color: #000000;
}
.buyTable .table td:first-child,
.sellTable .table td:first-child{
	text-align: center;
	color: #ffc107;
}
.buyTable .table td:last-child,
.sellTable .table td:last-child{
	text-align: center;
}
.buyTable .table td label small,
.sellTable .table td label small{
	display: block;
	font-size: 14px;
	color: #4c4c4c;
}
.landingTableTitle h3{
	text-align: center;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 30px;
}
.buyTable .landingTableTitle h3{
	color: #01b064;
}
.sellTable .landingTableTitle h3{
	color: #d53160;
}
.tableMoreLink{
	position: absolute;
	right: 15px;
	top: 38px;
	font-size: 14px;
	color: #787878;
}
.tableMoreLink:hover{
	color: #000000;
}
.greenBorderButton{
	border: 1px solid #01b064;
	color: #01b064;
	font-size: 16px;
	font-weight: 700;
	border-radius: 7px;
	box-shadow: none !important;
}
.greenBorderButton:hover{
	background: #01b064;
	color: #ffffff;
}
.redBorderButton{
	border: 1px solid #d53160;
	color: #d53160;
	font-size: 16px;
	font-weight: 700;
	border-radius: 7px;
	box-shadow: none !important;
}
.redBorderButton:hover{
	background: #d53160;
	color: #ffffff;
}
.currencyCalc {
    background: #606060;
    padding-bottom: 50px;
}
.currencyStatus {
    padding: 0px 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.currencyStatus h2{
	text-align: center;
	font-size: 30px;
	color: #000000;
	margin-right: 5px;
}
.currencyCalcCard {
    padding: 45px 25px;
}
.currencyInputGroup {
    display: flex;
    align-items: center;
	justify-content: center;
}
.currencyInputGroup .input-group{
	max-width: 220px;
	margin-right: 15px;
}
.currencyInputGroup label{
	margin-bottom: 0;
	margin-right: 15px;
}

.landingTable nav{
	padding-top: 15px;
}
.landingTable .nav-tabs .nav-link{
	color: #939393;
	font-size: 14px;
	border: 0;
	border-bottom: 2px solid transparent;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.landingTable .nav-tabs .nav-link img{
	margin-right: 5px;
}
.landingTable .nav-tabs .nav-link:hover{
	border-bottom: 2px solid #b17d2e;
	color: #000000;
}
.landingTable .nav-tabs .nav-item.show .nav-link,
.landingTable .nav-tabs .nav-link.active{
	border: 0;
	border-bottom: 2px solid #b17d2e;
	color: #000000;
	font-weight: 700;
	background: transparent;
}
.newsCardLanding{
	margin-bottom: 24px;
}
.landingNews {
    background: #ffffff;
    padding: 50px 0;
}
.landingNews h2{
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	margin-bottom: 50px;
}
.newsContentLand h3{
	font-size: 18px;
	font-weight: 600;
	color: #000000;
	line-height: 32px;
	margin-bottom: 18px;
}
.newsContentLand h3 a{
	color: #a6b0c3;
	font-size: 16px;
	margin-left: 5px;
	transition: .5s;
}
.newsContentLand h3 a:hover{
	color: #b17d2e;
}
.newsContentLand h6{
	font-size: 14px;
	display: flex;
	align-items: center;
}
.newsContentLand h6 span{
	color: #000000;
	margin-right: 10px;
}
.newsContentLand h6 span a{
	color: #000000;
	font-weight: 600;
	transition: .5s;
}
.newsContentLand h6 a:hover{
	color: #b17d2e;
}
.newsContentLand h6 label{
	margin-bottom: 0;
	background: #eff2f5;
	border-radius: 30px;
	padding: 3px 7px;
	display: inline-flex;
	align-items: center;
	font-weight: 600;
}
.newsContentLand h6 label img{
	margin-right: 5px;
}
.newsContentLand p{
	color: #58667e;
}
.newsImgLand img{
	border-radius: 15px;
}
.secFAQ{
	background: #f6f6f6;
	padding: 50px 0 60px;
}
.faqCont h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
}
.faqCont .card{
	border-radius: 0;
	border: 0;
}
.faqCont .card-header{
	border-bottom: 1px solid #ebebeb;
	padding: 15px 0;
}
.faqCont .btn-link.collapsed{
	color: #010101;
}
.faqCont .btn-link.collapsed span{
	font-size: 20px;
	color: #010101;
	transition: .5s;
}
.faqCont .card-header .btn-link i::before {
	content: "\f077" !important;
}
.faqCont .card-header .btn-link.collapsed i::before {
	content: "\f078" !important;
}
.faqCont .btn-link,
.faqCont .btn-link:hover i{
	color: #e8d132;
}
.faqCont .btn-link span,
.faqCont .btn-link:hover span{
	font-size: 20px;
	color: #e8d132;
}
.faqCont .card-header button{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	padding: 0;
	box-shadow: none !important;
	text-decoration: none;
	text-align: left;
}
.newsContentLand div#accordion h5 {
    font-size: 18px;
}
.faqCont .card-body{
	background: #f6f6f6;
	padding: 15px 0 5px;
}
.faqCont .card-body p{
	color: #727272;
}
.ayaReferral{
	background: #f6f6f6;
	padding: 40px 0;
}
.ayaReferral .container{
	display: flex;
}
.referralImg{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ayaReferralContent h2{
	font-size: 30px;
	color: #000000;
	margin-bottom: 30px;
	font-weight: 700;
}
.ayaReferralContent h2 span{
	font-weight: 400;
	color: #787878;
}
.ayaReferralContent{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.ayaReferralContent p{
	color: #727272;
	margin-bottom: 30px;
}
.ayaReferralContent ul{
	margin: 0 25px 25px;
}
.ayaReferralContent ul li{
	font-size: 24px;
	color: #ffffff;
	text-align: center;
	margin-bottom: 20px;
	position: relative;
}
.ayaReferralContent ul li:before{
	content: "";
	background: url(../images/referralCheck.png) no-repeat scroll 0 0;
	width: 19px;
	height: 18px;
	display: inline-block;
	margin-right: 12px;
}
.coinFeatures{
	background: #ffffff;
	padding: 60px 0;
}
.coinFeatures h2,
.ayaReferral h2 {
	font-size: 30px;
	color: #000000;
	margin-bottom: 30px;
	font-weight: 700;
	text-align: center;
}
.coinFeatures h2 span,
.ayaReferral h2 span {
	font-weight: bold;
	color: #787878;
}
.landFeatureCard{
	background: #f4f4f4;
	padding: 30px;
	border-radius: 15px;
	margin: 15px 0;
}
.featureBgColor1{
	background: #f7f6fe !important;
}
.featureBgColor2{
	background: #fff6f7 !important;
}
.featureBgColor3{
	background: #e9f2f9 !important;
}
.featureBgColor4{
	background: #e1f6fb !important;
}
.featureBgColor5{
	background: #f5fff4 !important;
}
.featureBgColor6{
	background: #fcf1eb !important;
}
.lfcImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.lfcContent{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}
.landFeatureCard h3{
	font-size: 18px;
	color: #000000;
	font-weight: 700;
}
.landFeatureCard p{
	font-size: 16px;
	color: #727272;
	margin-bottom: 0;
}
.BuySellTableSection{
	background: #ffffff;
	padding: 40px 0;
}
.btnPurple{
	background: #4e69ff;
	color: #ffffff !important;
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	padding: 10px 20px;
	transition: .5s;
	border: 1px solid #4e69ff;
}
.btnPurple:hover{
	background: transparent;
	color: #4e69ff !important;
}
.ayaReferralContent ul li span{
	font-size: 40px;
	color: #ffb549;
	font-style: italic;
	font-weight: 600;
}
.select-group-buySell{
	margin-right: 15px;
}
.select-group-buySell .css-2b097c-container{
	min-width: 100px;
}
.select-group-transfer{
	margin-right: 15px;
}
.select-group-transfer .css-2b097c-container{
	min-width: 180px;
}
.select-coinType .css-2b097c-container{
	min-width: 100px;
}
.select-coinType .css-2b097c-container .css-yk16xz-control,
.select-coinType .css-2b097c-container .css-1pahdxg-control{
	border: 0 !important;
	box-shadow: none !important;
	font-size: 20px !important;
}
.select-coinType .css-1uccc91-singleValue{
	color: #000 !important;
}
.select-coinType .css-tlfecz-indicatorContainer{
	color: #000 !important;
}
.currencyInputGroup .form-control{
	background: #ffffff !important;
}
.secFAQ.cmsPages {
	background: #fdfae7;
	padding: 100px 0 60px;
	min-height: 80vh;
}
.cmsBoxWhiteBox{
	padding: 30px;
	background: #fff;
	border-radius: 15px;
	box-shadow: 0 0 15px rgba(0,0,0,.1);
}
.secFAQ.cmsPages .faqCont .card-header,
.secFAQ.cmsPages .faqCont .card-body{
	background: #ffffff;
}
.faqGridCard{
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.faqGridCard h3{
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 10px;
}
.cmsBoxWhiteBox h2.cmsPageTitle {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
}
.cmsContent h3{
	font-size: 22px;
	font-weight: 700;
	color: #010101;
	margin-bottom: 25px;
}
.cmsContent .landPMCard h3{
	font-size: 18px;
}
.cmsContent p{
	color: #727272;
}
.userPages{
	background: #ffffff;
	padding: 100px 0 60px;
	min-height: 80vh;
}
.userBoxWhiteBox{
	padding:30px;
	margin-bottom: 30px;
	background: #fff;
	border-radius: 15px;
	box-shadow: 0 0 15px rgba(0,0,0,.1);
}
.userBoxUnderlineTitle{
	font-size: 20px;
	font-weight: 700;
	color: #000000;
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 15px;
	margin-bottom: 15px;
}
.userBoxTitle{
	font-size: 20px;
	font-weight: 700;
	color: #000000;
	margin-bottom: 30px;
}
.userBoxTitle span{
	font-weight: 400;
}
.profileDetailTable tr td{
	border: 0;
	padding: 8px 15px;
}
.profileDetailTable tr td label{
	margin-bottom: 0;
	color: #787878;
}
.profileDetailTable tr td p{
	margin-bottom: 0;
	color: #000000;
}
.profileDetailTable tr td p small i{
	font-size: 10px;
}
.blueText{
	color: #7a5f39 !important;
}
.greenText{
	color: #09a405 !important;
}
.ProfileDetailNameTitle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.ProfileDetailNameTitle a{
	text-decoration: none;
	font-size: 24px;
	font-weight: 700;
	color: #000;
}
.ProfileDetailNameTitle a img{
	margin-right: 15px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: inline-block;
}
.ProfileDetailNameTitle a span{
	color: #000000;
}
.input-group.flexInputGroup{
	align-items: center;
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 20px;
	margin-bottom: 20px;
}
.input-group.flexInputGroup .react-datepicker-wrapper{
	width: 100%;
	outline: none !important;
}
.profileDetailBuySellRow{
	border-bottom: 1px solid #e8e8e8;
	margin-bottom: 30px;
}
.pdBsCard{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 30px;
}
.pdBsCard h2{
	font-size: 20px;
	color: #000000;
	font-weight: 600;
	margin-bottom: 0;
}
.pdBsCard h2 span{
	font-size: 14px;
	color: #4c4c4c;
	font-weight: 400;
	margin-bottom: 0;
	display: block;
}
.pdBsCard h2 small{
	font-size: 14px;
	color: #4c4c4c;
	font-weight: 400;
}

/*Style 21-05-2021*/
.aletBoxCard{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.alertboxIcon{
	width: 30px;
	margin-right: 10px;
}
.alertboxIcon img{
	min-width: 24px;
}
.alert-primary{
	background: #ececff !important;
	border: 1px solid #d1d1f3 !important;
}
.alertboxDescription h5{
	font-size: 16px;
	color: #000000;
}
.alertboxDescription h5 span{
	font-weight: 600;
}
.redText{
	color: #cb0000 !important;
}
.alertboxDescription p{
	font-size: 16px;
	color: #787878;
	margin-bottom: 0;
}
.qrCodePh{
	margin-bottom: 35px;
}
.userPageTitle{
	font-size: 24px;
	color: #070707;
	font-weight: 700;
	margin-bottom: 20px;
}
.reffId{
	font-weight: 700;
	color: #000000;
	margin: 0 10px 0 5px;
}
.riTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
    margin-bottom: 0px;
}
.riTotal p{
	margin-bottom: 0;
}
.riTotal p span{
	font-weight: 700;
}
.referralIncomeTable{
	border: 0;
}
.referralIncomeTable th,
.referralIncomeTable td {
	border: 0 !important;
	padding: .45rem .75rem;
}
.referralIncomeTable td{
	color: #787878;
}
.ticketComments li{
	/* display: flex; */
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 20px;
}
.ticketDetails{
	border-bottom: 1px solid #e8e8e8;
}
.ticketComments li:last-child{
	margin-bottom: 0;
}
.ticketComments li:last-child .ticketDetails{
	border-bottom: 0px solid #e8e8e8;
}
.ticketComments p{
	font-size: 14px;
	color: #787878;
}
.ticketComments p.metaChatDetails{
	color: #000000;
	font-size: 14px;
	margin-bottom: 7px;
}
.ticketImg {
    min-width: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
}
.ticketImg img{
	border-radius: 50%;
	width: 26px;
	height: 26px;
	margin-bottom: 7px
}
.ticketImg h6{
	word-break: break-all;
	font-size: 14px;
	color: #000000;
	text-align: center;
	font-weight: normal;
}
.buySellCryptoBox .table{
	border: 0;
}
.buySellCryptoBox .table td{
	border-top:0;
	border-bottom: 1px solid #e5eaed;
}
.buySellCryptoBox .table tr:last-child td{
	border-bottom: 0;
}
.buySellCryptoBox .table td label {
    margin-bottom: 0;
    font-size: 20px;
    color: #000000;
}
.buySellCryptoBox .table td label small {
    display: block;
    font-size: 14px;
    color: #4c4c4c;
}
.buySellCryptoBox .table td label small small{
	display: inline-block;
	font-size: 8px;
	margin-left: 4px;
}
.buySellCryptoBox .table td:first-child {
    text-align: center;
    color: #ffc107;
}
.greyText{
	color: #c8c8c8 !important;
}
.buySellCryptoBox .greenBorderButton{
	background: #f3fdf9;
}
.buySellCryptoBox .greenBorderButton:hover{
	background: #01b064;
}
.greenBorderButton.active{
	background: #01b064;
	color: #ffffff;
}
.redBorderButton.active{
	background: #d53160;
	color: #ffffff;
}
.input-group .input-group-text{
	background: #f3f3f3 !important;
	border-color: #eaeaea !important;
}

.dashTotalBalance{
	margin-bottom: 30px;
}
.dashBalanceCard h2{
  font-size: 22px;
  color: #000000;
  margin-bottom: 15px;
}
.dashBalanceCard h2 span{
  font-size: 16px;
  color: #000000;
  margin-left: 7px;
}
.dashBalanceCard h2 small{
  display: block;
  font-size: 16px;
  color: #969696;
  margin-bottom: 7px;
}
.dashBalanceCard h3{
  font-size: 20px;
  color: #000000;
}
.dashBalanceCard h3 small{
  display: block;
  font-size: 16px;
  color: #969696;
  margin-bottom: 7px;
}
.dashChartCard ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
.dashChartCard ul li{
	margin-bottom: 10px;
}
.dashChartCard ul li label{
	font-size: 14px;
	margin-bottom: 0;
	color: #585052;
	width: 85px;
	display: inline-block;
}
.dashChartCard ul li span{
	font-size: 14px;
	margin-bottom: 0;
	color: #585052;
	display: inline-block;
}
.dashChartCard ul li label i{
	font-size: 8px;
	color: #e9e9e9;
	margin-right: 8px;
}
.orangeSquare{
	color: #f05b4f !important;
}
.redSquare{
	color: #d70206 !important;
}
.blueSquare{
	color: #6076ff !important;
}
.yelllowSquare{
	color: #f0b90b !important;
}
.greenSquare{
	color: #82bb47 !important;
}
.dashAccountTrade{
	margin-bottom: 35px;
}
.dashAccountTrade ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
.dashAccountTrade ul li{
	margin-bottom: 15px;
}
.dashAccountTrade ul li label{
	color: #787878;
	display: inline-block;
	width: 250px;
	margin-bottom: 0;
	vertical-align: top;
}
.dashAccountTrade ul li span.longPara {
    max-width: calc(100% - 250px);
    display: inline-block;
}
.dashTableTabbedContent .nav-tabs .nav-link{
	font-size: 18px;
	color: #969696;
	font-weight: 600;
	text-decoration: none;
	border-bottom: 2px solid transparent;
	border-top: 0 !important;
	border-right: 0 !important;
	border-left: 0 !important;
}
.strong{
	font-weight: 700;
}
.italics{
	font-style: italic;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover{
	color: #b17d2e;
border-radius: 0;
border-top: 0 !important;
border-left: 0 !important;
border-right: 0 !important;
border-color: #b17d2e;
}
.tableDashBoardStyle{
	border: 0;
	margin-bottom: 0;
}
.tableDashBoardStyle td{
	border: 0;
	font-size: 16px;
	color: #787878;
	padding-top: 5px;
	padding-bottom: 5px;
}
.tableDashBoardStyle td span{
	display: block;
}
.tableDashBoardStyle thead th{
	border: 0 ;
	font-weight: 400;
	color: #000000;
	padding-top: 5px;
	padding-bottom: 5px;
}
.dashTableTabbedContent{
	margin-bottom: 35px;
}
.WalletCard {
	padding: 30px;
	background: #fff;
	border-radius: 15px;
	box-shadow: 0 0 15px rgba(0,0,0,.1);
	margin-bottom: 30px;
}
.bgOne{
	background: #f7f6ff;
}
.bgTwo{
	background: #f7f6ff;
}
.bgThree{
	background: #f7f6ff;
}
.bgFour{
	background: #f7f6ff;
}
.walletCardTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
}
.walletCardTitle h4{
	font-size: 18px;
	color: #000000;
	font-weight: 700;
	margin-bottom: 0;
}
.WalletCard ul{
	margin: 50px 0 0 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
}
.WalletCard ul li{
	margin: 0 7px;

}
.WalletCard ul li a{
	text-decoration: none;
	color: #5a5a5a;
}
.WalletCard ul li a:hover{
	color: #b17d2e;
	text-decoration: underline;
}
.WalletCard h2{
	font-size: 28px;
	color: #000000;
	font-weight: 700;
	margin-bottom: 0;
}
.WalletCard h2 span{
	font-size: 22px;
}
.walletHistoryTable{
	border: 0;
}
.walletHistoryTable th{
	border: 0 !important;
	padding: 0rem .75rem .65rem;
	font-weight: 400;
	border-bottom: 1px solid #e8e8e8 !important;
}
.walletHistoryTable td {
	border: 0 !important;
	padding: .45rem .75rem;
}
.walletHistoryTable tbody tr:first-child td{
	padding-top: .65rem;
}
.walletHistoryTable td{
	color: #787878;
}
.depositBox h6{
	font-size: 16px;
	font-weight: 400;
	color: #000000;
	margin: 0 0 5px 0;
}
.depositBox p{
	font-size: 14px;
	font-weight: 400;
	color: #969696;
	margin: 0;
}
.flexLabel{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.labelBalance small{
	font-size: 16px;
	color: #969696;
}
.userBoxWhiteBox.depositBox{
	min-height: 540px;
}
.userBoxWhiteBox.withdrawBox{
	min-height: 540px;
}
.userBoxWhiteBox.withdrawBox .form-group{
	margin-bottom: 1.5rem;
}
.tableFilterHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.tableFilterCard {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.filterGroup {
    display: flex;
    align-items: center;
    margin-right: 18px;
}
.filterGroup label{
	margin-bottom: 0;
	margin-right: 10px;
}
.filterGroup .css-2b097c-container{
	min-width: 130px;
}
.btnType1.downLoadBtn {
    font-size: 14px;
    text-transform: none;
    border-radius: 7px;
    white-space: nowrap;
}
.filterGroup .input-group{
	max-width: 170px;
}
.myProfileBox .input-group-append .btnType1{
  text-transform: none;
}
.profileIconDropdown img{
	max-width: 24px;
}
.selectButton{
	box-shadow: none !important;
	background: #f3f3f3;
	border: 1px solid #eaeaea;
	width: 100%;
	padding: 10px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.selectedProfileThumb {
    display: flex;
    align-items: flex-end;
    height: 100%;
}
.custom-file-label{
	background: #f3f3f3 !important;
	box-shadow: none !important;
	border: 1px solid #eaeaea !important;
}
.custom-file-label:focus{
	border: 1px solid #b17d2e !important;
}
.custom-file-label::after {
	color: #ffffff !important;
	cursor: pointer;
	background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
}

.breadCrumb {
    margin-bottom: 20px;
}
.breadCrumb ul {
    display: flex;
	align-items: center;
}
.breadCrumb ul li{
	font-size: 18px;
	color: #070707;
	font-weight: 600;
}
.breadCrumb ul li a {
	text-decoration: none;
	color: #000;
}
.breadCrumb ul li.bcArrow{
	margin: 0 15px;
}

.search-wrapper {
    padding: 42px 0 0 0 !important;
    border-radius: 0 !important;
    border: 0 !important;
    position: relative;
}
.search-wrapper::after {
    content: "\f078";
    position: absolute;
    top: 7px;
    right: 14px;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 14px;
    color: #cecece;
}
.searchBox {
    padding: 5px 10px !important;
    background: #f3f3f3 !important;
    margin: 0 !important;
    border: 1px solid #eaeaea !important;
    position: absolute;
    top: 0;
    left: 0;
}
.chip{
	background: #ffffff !important;
	border: 1px solid #eaeaea !important;
	font-size: 14px !important;
	color: #787878 !important;
	border-radius: .25rem !important;
	width: calc(50% - 5px);
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
}
.icon_cancel{
	font-size: 14px !important;
}
.optionContainer li.highlight,
.optionContainer li:hover{
	background: #deebff !important;
	color: #000000 !important;
}
.ocTradeDetails {
    padding-right: 15px;
}
.ocPaymentDetails{
	padding-left: 15px;
}
.ocPaymentDetails h5{
	font-size: 16px;
	font-weight: 400;
	color: #000000;
	margin: 0 0 15px 0;
	padding: 0;
	line-height: 36px;
}
.ocPaymentDetails h5 span{
	background: #c6c6c6;
	padding: 12px 15px;
	border-radius: .25rem;
}
.postTrdeDetails{
	color: #767676;
}
.postTrdeDetails span{
	color: #000000;
}
.notificationList {
    width: 250px;
    padding: 15px 20px 10px;
    margin: 0;
    box-shadow: 5px 5px 5px rgba(0,0,0,.15)
}
.notificationList li{
	font-size: 14px;
	border-bottom: 1px solid #d4e1e7;
	padding-bottom: 12px;
	margin-bottom: 12px;
}
.notificationList li:last-child{
	border-bottom: 0;
	margin-bottom: 0;
}
.notificationList li h6{
	font-weight: 400;
	color: #7b7b7b;
	font-size: 12px;
	margin-bottom: 7px;
}
.notificationList li p{
	font-weight: 400;
	color: #5d7986;
	font-size: 14px;
	margin: 0;
	padding: 0;
}
.tcTitleFlex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}
.tcTitleFlex h5{
	font-size: 16px;
	color: #000000;
	margin: 0;
}
.tcTitleFlex h5:first-child {
	text-align: left;
}
.tcTitleFlex h5:last-child {
	text-align: right;
}
.tcTitleFlex h5 span{
	display: block;
	font-size: 18px;
}
.tcTitleFlex h3{
	font-size: 20px;
	color: #000000;
	margin: 0;
	padding: 0;
	font-weight: 700;
}
.greyChartBox{
	background: #f3f3f3;
	border: 1px solid #eaeaea;
	border-radius: 10px;
	padding: 15px;
}
.greyChartBox p{
	background: #ffffff;
	border-radius: 7px;
	padding: 12px 15px;
	border: 1px solid #eaeaea;
	/* max-width: 75%; */
}
.greyChartBox .form-group .form-control{
	background: #ffffff;
	height: auto;
	padding: 10px 15px;
}
.buyerChat{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
}
.SellerChat{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}
.greyChartBox ul li h6{
	color: #787878;
	font-weight: 400;
	margin: 0 0 7px 0;
	padding: 0;
	font-size: 12px;
}
.paidButtons {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 20px 0;
}
.chatAgreeCheck{
	margin-top: 18px;
}
.chatAgreeCheck .checkbox label {
    justify-content: flex-start;
    margin: 0;
}
.paidButtons .btnBlue {
    white-space: nowrap;
    margin-right: 20px;
}
.paidButtons .custom-file-label{
	margin-bottom: 0;
}
.contractDetails h4{
	font-size: 18px;
	color: #000000;
	font-weight: 400;
}
.contractDetails p{
	font-size: 16px;
	color: #787878;
}
.withdrawConfirmDetails h3 {
	font-weight: 400;
	color: #000;
	font-size: 18px;
	text-align: center;
	margin-bottom: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.withdrawConfirmDetails h3 small{
	display: block;
	font-size: 16px;
	color: #000000;
	margin-bottom: 8px;
}
.buttonSup {
	margin-top: 10px;
}
.modal-content .modal-title{
	text-align: center;
	font-size: 22px;
	font-weight: 700;
	color: #000;
	margin-left: auto;
	margin-right: auto;
}
.modal-content{
	border-radius: 15px !important;
}
.tradeCancelDetails h5{
	font-size: 16px;
	color: #000000;
	text-align: center;
	margin-bottom: 30px;
}
.disputeDescription{
	color: #787878;
}
.feedBackForm{
	background: #f6f6f6;
	padding: 15px 30px 10px;
	border-radius: .25rem;
	position: relative;
}
.currentPrice {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    padding: 40px 0;
    margin-bottom: 30px;
}
.currentPrice h2{
	font-size: 20px;
	font-weight: 700;
	color: #000000;
	margin: 0;
	padding: 0;
	text-align: center;
}
.currentPrice h2 small{
	display: block;
	margin-top: 10px;
	font-size: 14px;
	color: #4c4c4c;
}
.currentPrice h2 span{
	font-weight: 700;
}
.asLeftProfileDetails {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.asLeftProfileDetails img{
	min-width: 20px;
	width: 20px;
	margin-right: 15px;
}
.asLeft h4{
	font-weight: 400;
	color: #000000;
	font-size: 18px;
	margin-bottom: 20px;
	margin-top: 0;
}
.asRight ul li{
	margin-bottom: 20px;
}
.asRight ul li h4{
	font-weight: 400;
	color: #000000;
	font-size: 18px;
	margin-bottom: 10px;
	margin-top: 0;
}
.asRight ul li p{
	font-weight: 400;
	color: #787878;
	font-size: 16px;
	margin-bottom: 10px;
	margin-top: 0;
}
.profileSellerText h3{
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 15px;
	margin-top: 0;
}
.profileSellerText p{
	font-size: 16px;
	color: #000000;
	margin-bottom: 10px;
}
.greyBg{
	background: #f3f3f3;
	border: 1px solid #eaeaea;
	border-radius: 7px;
	padding: 30px 20px;
}
.asRight ul li .greyBg p{
	margin-bottom: 0;
}
.bankTerms{
	border-top:1px solid #eaeaea;
	padding-top: 20px;
}
.bankTerms h4{
	color: #000000;
	font-size: 18px;
	margin-bottom: 15px;
}
.bankTerms p{
	font-size: 16px;
	color: #787878;
}
.homeAccordian a {
    text-decoration: unset;
}
.news_page_inner {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
}
.blogWhiteCard {
	background: #ffffff;
	padding: 15px;
	border-radius: .5rem;
	box-shadow: 0 0 5px rgb(0 0 0 / 10%);
	margin-bottom: 30px;
}
.blogSidebarTitle {
	font-size: 20px;
	color: #fff;
	padding: 15px;
	background-color: #616161;
	border-top-right-radius: .5rem;
	border-top-left-radius: .5rem;
	text-transform: uppercase;
}
.blogSideBarLinks {
	margin: 0;
	padding: 10px 15px 5px;
	list-style: none;
}
.blogSideBarLinks li {
	padding-bottom: 15px;
	position: relative;
	padding-left: 25px;
}
.blogSideBarLinks li::before {
	content: "\f101";
	font-family: "Font Awesome 5 Free" !important;
	font-display: swap;
	font-weight: 700;
	position: absolute;
	left: 0;
	top: 0;
	color: #a4a4a7;
}
.blogSideBarLinks li a {
	font-size: 15px;
	color: #4e4e4e;
	text-decoration: unset;
}
.blogSideBarLinks li a:hover {
	color: #b17d2e;
}
.newsCardLanding ul {
    padding-left: 30px;
}
.newsCardLanding ul li {
	list-style: square;
	color: #58667e;
}
.depositBox i.fa.fa-copy {
    color: #fff;
}
span.post_id {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.custom-file {
	z-index: 0 !important;
}
.navbar-brand img {
	width: 200px;
}
.ayaReferral h2 {
	text-align: left;
}
.landingTable .table-responsive {
	height: 300px;
	overflow-y: auto;
}
body .terms span u {
	text-decoration: none !important;
}
div#as-react-datatable-container {
    margin-bottom: 15px;
}
.pagination .page-link input[type="text"] {
	background-color: transparent !important;
	border: none !important;
	font-size: 14px !important;
}





/* Dark Theme */
body.dark_theme {
    background: #161616;
	color: #fff;
	font-family: Lato, sans-serif !important;
}
body.dark_theme .terms span {
	color: #fff !important;
	font-family: Lato, sans-serif !important;
}
body.dark_theme .terms span strong {
	color: #bf922f !important;
	font-family: Lato, sans-serif !important;
}
.dark_theme .landingBanner {
    background: #161616 url(../images/heademaprbg.png) no-repeat scroll center center;
    padding: 100px 0 30px;
}
.dark_theme .currencyCalc {
    background: #161616;
}
.dark_theme .landingTable {
    background: #323232;
}
.dark_theme .landingTable .nav-tabs .nav-item.show .nav-link, 
.dark_theme .landingTable .nav-tabs .nav-link.active {
    border-bottom: 2px solid #f3e133;
    color: #f3e133;
}
.dark_theme .currencyStatus h2 {
	color: #fff;
}
.dark_theme .currencyInputGroup label {
	color: #f0f0f0;
}
.dark_theme .css-yk16xz-control {
    background-color: #4c4c4c !important;
	border-color: #4c4c4c !important;
}
.dark_theme .select-coinType .css-1uccc91-singleValue {
    color: #e6e6e6 !important;
}
.dark_theme .css-1pahdxg-control {
	background-color: #4c4c4c !important;
	border-color: #4c4c4c !important;
}
.dark_theme .css-26l3qy-menu {
	background-color: #323232 !important;
	border-color: #323232 !important;
	color: #fff;
}
.dark_theme .css-26l3qy-menu > div > div:hover  {
	background-color: #161616 !important;
	border-color: #161616 !important;
	color: #fff;
}

.dark_theme .css-6q0nyr-Svg {
	fill: #82817b;
}
.dark_theme .ocPaymentDetails h5 span {
	color: #000;
}
.dark_theme .ocPaymentDetails h5 {
	color: #fff;
}
.dark_theme .landingNav.Scroll.up,
.dark_theme .landingNav {
    background: #262626 !important;
}
.dark_theme .mainFooter {
	background: #262626 !important;
}
.dark_theme .landingTable .nav-tabs .nav-link:hover {
    border-bottom: 2px solid #f3e133;
    color: #f3e133;
}
.dark_theme .BuySellTableSection {
    background: #1d1d1d;
}
.dark_theme .coinFeatures,
.dark_theme .ayaReferral,
.dark_theme .secFAQ {
    background: #161616;
}
.dark_theme .coinFeatures h2,
.dark_theme .paymentMethods h2,
.dark_theme .ayaReferral h2 {
    color: #b17d2e;
}
.dark_theme .coinFeatures h2 span,
.dark_theme .paymentMethods h2 span,
.dark_theme .ayaReferral h2 span {
    color: #afafaf;
}
.dark_theme .featureBgColor1 {
    background: #323232 !important;
}
.dark_theme .landFeatureCard h3 {
    color: #b17d2e;
}
.dark_theme .landFeatureCard p {
    color: #c9c9c9;
}
.dark_theme .processSteps,
.dark_theme .paymentMethods,
.dark_theme .landingNews {
    background: #1d1d1d;
}
.dark_theme .processSteps h2 {
    color: #e0e0e0;
}
.dark_theme .verifyContentLanding img {
    filter: grayscale(1) brightness(1) invert(1);
}
.dark_theme .verifyContentLanding img {
    filter: none;
}
.dark_theme .verifyContentLeft ul li a {
    color: #dcdcdc;
}
.dark_theme .landingVerifyCard {
    border-bottom: 1px solid #3d3d3d;
}
.dark_theme .landingVerifyCard:last-child {
	border-bottom: 0;
}
.dark_theme .landPMCard h3 {
    color: #a4a4a4;
}
.dark_theme .landingNews h2,
.dark_theme .faqCont h2 {
	color: #b17d2e;
}
.dark_theme .newsContentLand h3 {
	color: #fff;
}
.dark_theme .newsContentLand h6 span {
    color: #a0a0a0;
}
.dark_theme .newsContentLand p {
    color: #8f8f8f;
}
.dark_theme .footerBottomBox {
    border-top: 1px solid #393939;
}
.dark_theme .newsContentLand h6 label {
    background: #505050;
	color: #d3d3d3;
}
.dark_theme .currencyInputGroup .form-control {
    background: #4c4c4c !important;
	border: 1px solid #4c4c4c;
}
.dark_theme .input-group .input-group-text {
    background: #4c4c4c !important;
    border-color: #4c4c4c !important;
    color: #eee;
}
.dark_theme .nav-tabs {
    border-bottom: 1px solid #494949;
}
.dark_theme .footerSubscribe .form-control {
    background: #2b2b2b;
    border-color: #2b2b2b !important;
}
.dark_theme .buyTable .table td label, 
.dark_theme .sellTable .table td label {
	color: #b4b4b4;
}
.dark_theme .buyTable .table td label small, 
.dark_theme .sellTable .table td label small {
	color: #878787;
}
.dark_theme .userPages {
	background: #161616;
}
.dark_theme .userPageTitle {
	color: #f3e133;
}
.dark_theme .userBoxWhiteBox {
	background: #323232;
}
.dark_theme .userBoxUnderlineTitle {
	color: #fff;
	border-bottom: 1px solid #494949;
}
.dark_theme  .dashBalanceCard h2 {
	color: #f3e133;
}
.dark_theme .dashBalanceCard h2 span {
	color: #f3e133;
}
.dark_theme .dashBalanceCard h3 {
	color: #f3e133;
}
.dark_theme .dashChartCard ul li label {
	color: #fff;
}

.dark_theme .dashChartCard ul li span {
	color: #bfbfbf;
}
.dark_theme .table.bankList thead th {
	border-bottom: 1px solid #515151;
	color: #bbb;
}
.dark_theme .table.bankList td, .dark_theme .table.bankList th {
	color: #fff;
}
.dark_theme .buttonNote p span {
	color: #fff;
}
.dark_theme  .dashAccountTrade ul li label {
	color: #9b9a9a;
}
.dark_theme .nav-tabs .nav-item.show .nav-link, .dark_theme .nav-tabs .nav-link.active, .dark_theme  .nav-tabs .nav-link:hover {
	color: #f3e133;
	border-color: #f3e133;
	background: transparent;
}
.dashTableTabbedContent .nav-tabs {
	margin-bottom: 16px;
}
.dark_theme .dashTableTabbedContent .input-group .input-group-text {
	background: #323232 !important;
	border-color: #323232 !important;
	color: #eee;
}
.dark_theme .asrt-page-length .form-control {
	color: #fff;
	background-color: #323232;
	border: 1px solid #393939 !important;
}
.dark_theme .table thead th {
	vertical-align: bottom;
	border-bottom: 1px solid #4a4a4a !important;
	color: #bbb;
}

.dark_theme .table-bordered td, .dark_theme .table-bordered th {
	border: 1px solid #4a4a4a;
	color: #fff;
}
.dark_theme .table-bordered td p {
	color: #fff;
}
.btn-primary {
	color: #fff;
	border-color: #b17d2e;
	background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
}
.btn-primary:hover {
	color: #fff;
	background: linear-gradient(115deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
	border-color: #b17d2e;
}
.dark_theme  .page-item.disabled .page-link {
	color: #999;
	background-color: #161616;
	border-color: #484848;
}
.dark_theme .page-link {
	color: #f2e130;
	background-color: #161616;
	border: 1px solid #464545;
}
.dark_theme .riTotal {
	border-bottom: 1px solid #555;
}
.dark_theme .alertboxDescription h5 {
	color: #fff;
}
.dark_theme .checkbox .cr, .dark_theme .radio .cr {
	border: 1px solid #515151;
	background: #161616;
}
.dark_theme .radio .cr {
	border: 1px solid #edd933;
	background: #161616;
}
.dark_theme textarea.form-control {
	background-color: #161616;
	border: 1px solid #464646;
}
.dark_theme .checkbox .cr .cr-icon, .dark_theme .radio .cr .cr-icon {
	color: #edd933;
}
.dark_theme  .tdMain {
	border-top: 1px solid #515151;
}
.dark_theme .supporTicketAccordian #accordion .card {
	border: 1px solid #4a4a4a;
	background: #161616;
}
.dark_theme .subPageTitle {
	color: #f3e133;
}
.dark_theme .stHeadText small {
	color: #a6a6a6;
}
.dark_theme .bgTwo {
	background: #323232;
}
.dark_theme .stHeadText {
	color: #fffefe;
}
.dark_theme  .supporTicketAccordian #accordion .card-header [data-toggle="collapse"] .fa::before {
	content: "\f068";
	color: #f3e133;
}
.dark_theme .supporTicketAccordian #accordion .card-header:hover .btn i::before {
	color: #f3e133 !important;
}
.dark_theme  .bgOne {
	background: #323232;
}
.dark_theme .supporTicketAccordian #accordion .card-body {
	background: transparent;
}
.dark_theme .supportSubject h4 {
	border-bottom: 1px solid #4a4949;
}
.dark_theme .supporTicketAccordian #accordion .card-body p {
	color: #fff;
}
.dark_theme .ticketDetails {
	border-bottom: 1px solid #4a4949;

}
.dark_theme .custom-file-label {
	background: #161616 !important;
	border: 1px solid #424040 !important;
}
.dark_theme .custom-file-label::after {
	color: #ffffff !important;
	cursor: pointer;
	background: linear-gradient(15deg, rgba(177,125,46,1) 0%, rgba(241,222,51,1) 50%, rgba(177,125,46,1) 100%);
}
.dark_theme .supportSubject h4 {
	color: #e5cc32;
}
.dark_theme .bgFour {
	background: #323232;
}
.dark_theme .walletCardTitle h4 {
	color: #fff;
}
.dark_theme .WalletCard h2 {
	color: #f3e133;
}
.dark_theme .WalletCard ul li a {
	color: #b3b3b3;
}
.dark_theme .WalletCard ul li a:hover {
	color: #f3e133;
}
.dark_theme .linkTabs .dropdown-menu {
	background: #323232;
	filter: drop-shadow(0px 2px 4px #000);
}
.dark_theme  .linkTabs .dropdown-menu a {
	text-decoration: none;
	color: #fff;
}
.dark_theme .notificationList li {
	border-bottom: 1px solid #4f4f4f;
}
.dark_theme  .linkTabs .dropdown-menu a:hover {
	background: #161616;
}
.dark_theme .ProfileDetailNameTitle a {
	color: #f2e130;
}
.dark_theme  .ProfileDetailNameTitle a span {
	color: #fff;
}

.dark_theme .notificationList li h6 {
	color: #b0b0b0;
}
.dark_theme .notificationList li p {
	color: #fff;
}
.dark_theme .notificationList .blueText {
	color: #f3e133 !important;
}
.dark_theme .userBoxTitle {
	color: #fff;
}
.dark_theme input[type="email"], .dark_theme input[type="number"], .dark_theme input[type="password"], .dark_theme input[type="text"] {
	background-color: #161616 !important;
	border: 1px solid #484848 !important;
	color: #fff;
}
.dark_theme .input-group .form-control:focus {
	border-color: #f6ec50 !important;
}
.dark_theme .input-group.flexInputGroup {
	border-bottom: 1px solid #4d4d4d;
}
.dark_theme .myAdListCard p small {
	display: block;
	color: #aea8a8;
}
.dark_theme .myAdListCard p {
	color: #fff;
	font-size: 14px;
}
.dark_theme  .profileDetailTable tr td label {
	color: #acabab;
}
.dark_theme .profileDetailTable tr td p {
	color: #fff;
}
.dark_theme  .blueText {
	color: #e8d132 !important;
}
.dark_theme  .form-control:disabled{
	background: #323232 !important;
	opacity: 0.5;
}
.dark_theme  .ReactFlagsSelect-module_selectBtn__19wW7 {
	color: #fff;
	background: #323232;
}
.dark_theme  .ReactFlagsSelect-module_selectOptions__3LNBJ {
	border: 1px solid #5e5d5d;
	background: #323232;
}
.dark_theme .ReactFlagsSelect-module_filterBox__3m8EU {
	background: #323232;
}
.dark_theme .ReactFlagsSelect-module_selectOption__3pcgW:hover, .dark_theme .ReactFlagsSelect-module_selectOption__3pcgW:focus {
	outline: none;
	background: #161616;
}
.dark_theme .alert-primary {
	background: #151515 !important;
	border: 1px solid #444 !important;
}
.dark_theme  .css-1uccc91-singleValue {
	color: #fff;

}
.dark_theme  .backPageTitle {
	color: #fff;
}
.dark_theme .input-group .form-control {
	border-right: 0 !important;
	background: #161616;
	border: 1px solid #4a4a4a;
}
.dark_theme .css-yk16xz-control{
	border-color: #161616;
}
.dark_theme .form-control {
	color: #fff;
	background-color: #161616;
}
.dark_theme .modal-content {
	background-color: #323232;
}
.dark_theme .modal-header{
	border-bottom: 1px solid #5f5f60;
}
.dark_theme .modal-footer  {
	border-bottom: 0px solid #5f5f60;
	border-top: 1px solid #5f5f60;
}
.dark_theme .buySellCryptoBox .table td label {
	color: #f0dd33;
}
.dark_theme  .buySellCryptoBox .table td label small {
	color: #9d9d9d;
}
.dark_theme .breadCrumb ul li {
	color: #fff;
}
.dark_theme .tcTitleFlex h5 {
	color: #fff;

}
.dark_theme .currentPrice {
	border-top: 1px solid #4f4f4f;
	border-bottom: 1px solid #4f4f4f;
}
.dark_theme  .currentPrice h2 {
	color: #f0dd33;
}
.dark_theme .currentPrice h2 small {
	color: #d7d7d7;
}
.dark_theme .asLeft h4 {
	color: #979797;
}
.dark_theme  .asRight ul li p {
	color: #979797;
}
.dark_theme .profileSellerText p {
	color: #979797;
}
.dark_theme  .asRight ul li h4 {
	color: #979797;
}
.dark_theme .bankTerms {
	border-top: 1px solid #464646;
}
.dark_theme .bankTerms h4 {
	color: #fff;
}
.dark_theme .alertboxDescription p {
	color: #979797;
}
.dark_theme .agreeContent a{
	color: #f1de33;
}
.dark_theme .backPageTitle a {
	color: #f0dd33;
}
.dark_theme .historyAccordian #accordion .card-header:hover .btn.btn-link.collapsed {
	color: #ebd533;
}
.dark_theme  .historyAccordian #accordion .card-header .btn i {
	color: #ebd533;
}
.dark_theme .historyAccordian #accordion .card-header [data-toggle="collapse"].collapsed .fa::before {
	content: "\f067";
	color: #ebd533;
}
.dark_theme .historyAccordian #accordion .card-header .btn.btn-link.collapsed {
	color: #fff;
}
.dark_theme  .historyAccordian #accordion .card-body {
	background: #161616;
}
.pagination {
	justify-content: space-evenly;
	align-items: center;
}
.dark_theme  h2.balanceTitle {
	font-size: 20px;
	color: #f0dd33;
	font-weight: 700;
	text-align: center;
	margin: 25px 0 20px;
}
.dark_theme  .historyAccordian #accordion .card-header .btn {
	color: #fff;
}
.dark_theme .historyAccordian #accordion .card-header [data-toggle="collapse"] .fa::before {
	color: #f0dd33;
}
.dark_theme  .buySellCryptoBox .greenBorderButton {
	background: transparent;
}
.dark_theme .contractDetails h4 {
	color: #fff;
}
.dark_theme .contractDetails p {
	color: #b7b2b2;
}
.dark_theme  .greyChartBox {
	background: #161616;
	border: 1px solid #4d4a4a;
}
.dark_theme .greyChartBox p {
	background: #323232;
	border: 1px solid #3c3c3c;
}
.dark_theme .breadCrumb ul li a {
	color: #e8d132;
}
.dark_theme .modal-content .modal-title {
	color: #fff;
}
.dark_theme .tradeCancelDetails h5 {
	color: #9f9f9f;
}
.dark_theme .dropdown-menu {
	color: #fff;
}
.dark_theme .cmsBoxWhiteBox {
	background: #323232;
}
.dark_theme .blogWhiteCard {
	background: #323232;
}
.dark_theme .blogSideBarLinks li a {
	color: #c8c1c1;
}
.dark_theme .blogSideBarLinks li a:hover {
	color: #f0dc33;
}
.dark_theme  .newsContentLand h6 span a {
	color: #fff;
}
.dark_theme .newsContentLand h6 span a:hover {
	color: #e8d032;
}
.navbar-light .navbar-toggler-icon {
	filter: brightness(0) invert(1);
	-webkit-filter: brightness(0) invert(1);
}
.dark_theme .css-b8ldur-Input input[type="text"] {
	background-color: #4c4c4c !important;
}
.dark_theme .secFAQ.cmsPages .faqCont .card-header, 
.dark_theme .secFAQ.cmsPages .faqCont .card-body {
    background: #323232;
}
.dark_theme .faqCont .card-header {
    border-bottom: 1px solid #464545;
}
.dark_theme .faqCont .btn-link span, 
.dark_theme .faqCont .btn-link:hover span {
    color: #ffffff;
}
.dark_theme .faqCont .card-body p {
	color: #cccccc;
}
.dark_theme .card {
	background: #323232;
}
.dark_theme .faqCont .btn-link, 
.dark_theme .faqCont .btn-link:hover i {
	color: #e8d132;
}
.dark_theme .asrt-page-length .input-group-addon {
	background: #323232;
}
.dark_theme .crediantialsBox {
    background: #1d1d1d;
}
.dark_theme .formBox {
    background: #323232;
}
.dark_theme .formBox .form-group label {
    color: #fff;
}
.dark_theme .crediantialTitle {
    color: #efdb33;
}
.dark_theme .formFooter h6 {
    color: #c4c4c4;
}


.light_dark_sectoin a {
	display: block;
	padding: 0;
	background: #7d7d7d;
	font-size: 20px;
	width: 30px;
	height: 30px;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	cursor: pointer;
}

.light_dark_sectoin a .fa-sun{
	color: #f6e333;
}
.nextbtn{
	justify-content: center;
}

.padin_link .nav-item .nav-link {
	padding: 0 14px;
}

/* Media Queries */





@media screen and (max-width: 1199px) {
	.container {
		max-width: 98%
	}
}
@media screen and (max-width: 991px) {
	.landingBannerContent h2{
		font-size: 30px;
	}
	.landingBannerContent h6{
		font-size: 18px;
	}
	.currencyStatus h2{
		font-size: 24px;
	}
	.currencyInputGroup{
		flex-direction: column;
	}
	.currencyInputGroup label{
		margin-bottom: 10px;
	}
	.select-group-buySell{
		margin-bottom: 20px;
		margin-right: 0;
	}
	.currencyInputGroup .input-group{
		margin-bottom: 10px;
		margin-right: 0;
	}
	.select-group-transfer{
		margin-bottom: 20px;
		margin-right: 0;
	}
	.processSteps h2{
		font-size: 28px;
	}
	.landingVerifyCard h3{
		font-size: 18px;
	}
	.ayaReferralContent h2{
		font-size: 28px;
	}
	.ayaReferralContent ul li{
		font-size: 18px;
	}
	.paymentMethods h2{
		font-size: 28px;
	}
	.landPMCard h3{
		font-size: 18px;
	}
	.satisfiedCustomer h2{
		font-size: 28px;
	}
	.ourPartners h2{
		font-size: 28px;
	}
	.footerSubscribe {
		margin-top: 30px;
	}
	.footerSubscribe h3{
		text-align: left;
		font-size: 18px;
	}
	.landingNav .navbar-collapse {
		background: transparent;
		padding: 20px;
	}
	.padin_link .nav-item .nav-link {
		padding: 15px 14px;
	}
	.landingNav .navbar-collapse .d-flex {
		display: block !important;
	}
	.landingNav .navbar-collapse .navbar-nav {
		margin-right: 0 !important;
	}
}
@media screen and (max-width: 767px) {
	.landingBannerContent h2{
		text-align: center;
		margin-bottom: 10px;
	}
	.landingBannerContent h6{
		text-align: center;
	}
	.landingBannerContent{
		margin-bottom: 15px;
	}
	.landingTable .nav-tabs .nav-link{
		padding-left: 12px;
		padding-right: 12px;
	}
	.landingTable .nav-tabs .nav-link img{
		display: none;
	}
	.landPMCard{
		margin-bottom: 30px;
	}
	.partnersLogo {
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    margin-top: 40px;
	    flex-wrap: wrap;
	    flex-direction: column;
	}
	.partnersLogo a{
		margin-bottom: 30px;
	}
	.footerLogo{
		margin-bottom: 30px;
	}
	.ayaReferral .container{
		flex-direction: column;
	}
	.ayaReferral{
		padding-top: 120px;
		padding-bottom: 160px;
	}
	.referralImg{
		margin-bottom: 30px;
	}
	.landingBannerContent{
		padding-top: 30px;
	}
	.dashBuyCard{
		margin-bottom: 15px;
	}
	#nav-tab-dashPairMain .nav-link{
		padding-left: 14px;
		padding-right: 14px;
	}
	.verifyContentLeft {
		background-size: contain;
	}
	.currencyCalc {
		padding-bottom: 10px;
	}
	.newsContentLand h6 {
		display: block;
	}
	.newsContentLand h6 label {
		margin: 10px 0;
	}
	.ocTradeDetails .row.mb-2 .col-md-4 {
		margin-bottom: 8px;
	}
	ul.pagination {
		justify-content: flex-start !important;
		margin-top: 10px;
	}
}
@media screen and (max-width: 575px) {
	.currencyStatus{
		flex-direction: column;
	}
	.footerBottomBox {
		flex-direction: column;
	}
	.footerBottomBox ul {
		margin-top: 10px;
	}
	.footerBottomBox ul li:first-child{
		margin-left: 0;
	}
	.formBox .nav-tabs .nav-item.nav-link:first-child {
		margin-left: 10px
	}
	.formBox .nav-tabs .nav-item.nav-link:last-child {
		margin-right: 10px
	}
	.formBox .nav-tabs .nav-item.nav-link {
		font-size: 16px
	}
	.formBox .tab-pane {
		padding: 25px 10px
	}
	.formFooter ul li img {
	    max-width: 34px;
	}
	.pageNavStyle a{
		min-width: calc(50% - 8px);
		margin-bottom: 15px !important;
		margin-right: 15px;
	}
	.gsdHead{
		flex-wrap: wrap;
	}
	.pageNavStyle a:nth-child(even){
		margin-right: 0;
	}
	.dashRadio{
		flex-wrap: wrap;
	}
	.dashRadio label{
		display: block;
		width: 100%;
		margin-bottom: 10px !important;
	}
	.dbmHead{
		flex-direction: column;
	}
	.table td, .table th{
		white-space: nowrap;
	}
	.table td img{
		min-width: 18px;
	}
	.dashBuySellPair h1{
		font-size: 18px;
	}
	.depositCard p{
		word-break: break-all;
	}
	.pfrCard{
		flex-wrap: wrap;
	}
	#nav-tab-subMenu{
		padding: 0 0px 0 15px;
	}
	#nav-tab-subMenu a{
		margin: 0 !important;
		width: calc(50% - 15px) !important;
		display: inline-block;
		margin-bottom: 15px !important;
		margin-right: 15px !important;
	}
	#nav-tab-subMenu a span{
		display: block;
		text-align: center;
		margin: 0 auto 10px;
	}
	.p2pRadio{
		flex-wrap: wrap;
	}
	.p2pRadio label{
		display: block;
		width: 100%;
		margin-bottom: 7px !important;
	}
	.balanceTitle{
		margin-bottom: 20px !important;
	}
	.balanceTitle small{
		display: block;
		margin-bottom: 10px;
	}
	.postADAgreement .cr{
		min-width: 18px;
	}
	.activityFilterHead{
		flex-wrap: wrap;
		justify-content: center;
	}
	/*.pageNavStyle {
	    position: fixed;
	    width: 100%;
	    left: 0;
	    bottom: 0;
	    z-index: 999;
	    background: #f8f8f8;
	    padding: 15px;
	}*/
	.tableFilterHead {
		display: block;
	}
	.tableFilterCard {
		display: block;
		margin-top: 15px;
	}
	.tableFilterCard .btnType1.downLoadBtn {
		margin-top: 15px;
	}
	.flexLabel {
		display: block;
	}
	.flexLabel span {
		display: block;
	}
	.page-link {		
		padding: 5px;
		font-size: 14px;
		margin-top: 10px;
	}
	.table-foot {
		font-size: 14px;
	}
}

@media screen and (max-width: 350px) {
	.g-recaptcha {
		transform: scale(0.75);
    	margin-left: -35px;
	}
	.page-link {		
		padding: 4px;
		font-size: 13px;
	}
}
